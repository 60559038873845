import {
  request
} from '@/utils/httpRequest';


/* 获取精选内容列表 */
export function getVideoList(data) {
  return request('/web/video/list',data,{ method: 'get' })
}

/* 获取精选内容详情 */
export function getVideoInfo(id) {
  return request('/web/video/' + id,{},{ method: 'get' })
}



// export function phoneCodeIsTrue(data) {
//   return request('/website/user/phoneCodeIsTrue',data,{ method: 'get' })
// }


// export function verifyInfo(data) {
//   return request('/website/user/verifyInfo',data,{ method: 'post' })
// }




<template>
  <div class="handpickBox">
    <div class="handpick-1200">
      <div class="handpickVideo">
        <video class="videoAvatar" id="myVideo" ref="myVideo" :src="handpickInfo.url" preload="auto" controls type="video/mp4">您的浏览器不支持视频播放</video>
      </div>
      <div class="handpickTitle">{{handpickInfo.title}}</div>
      <div class="handpickTiem">发布时间：{{handpickInfo.publishTime}}</div>
      <div style="padding: 10px 30px 150px 30px;" v-html="handpickInfo.details"></div>



    </div>
  </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { getVideoInfo } from '@/api/handpick/handpick'
  import { getToken,getVipStatus } from '@/utils/auth'
  export default {
    components: {},
    data() {
      return {
        handpickInfo:{
          url:"",
          title:"",
          publishTime:"",
          details:"",
        },
        vipStatus:getVipStatus(),
        token:getToken(),
      };
    },
    mounted() {
      document.getElementById('myVideo').controlsList = "nodownload";
    },
    created() {
      var id = this.$route.query.id;
      this.getInfo(id);

    },
    methods: {
      /* 精选内容详情 */
      getInfo(id){
        if(!this.token){
          this.$confirm('您还未登录，请登录后查看', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              this.$router.push({ name: 'Login', query: {} });
          }).catch(() => {
              this.$router.push({ path: "/" });
              sessionStorage.setItem('activeIndex', "1")
          });
          return
        }

        if(this.vipStatus == 1){
          getVideoInfo(id).then(res => {
          	if (res.code === 200) {
          	 this.handpickInfo = res.data;
          	}
          });
        }else{
          this.$confirm('您还不是会员，成为会员可查看', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
              this.$router.push({ path: "/" });
              sessionStorage.setItem('activeIndex', "1")
          }).catch(() => {
              this.$router.push({ path: "/" });
              sessionStorage.setItem('activeIndex', "1")
          });
        }

      },
    }
  };
</script>

<style lang="scss" scoped>
  .handpickBox{
    width: 100%;
    min-height: 100vh;
  }
  .handpickBackImg{
    width: 100%;
    height: 360px;
    margin-bottom: 80px;
  }
  .handpick-1200{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }
  .handpickVideo{
    width: 100%;
    height: 600px;
    margin: 50px 0;
    background-color: #000000;
  }
  .videoAvatar{
    width: 100%;
    height: 100%;
  }
  .handpickTitle{
    font-size: 26px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    text-align: center;
    color: #000000;
    letter-spacing: 1.4px;
    margin-bottom: 15px;
  }
  .handpickTiem{
    font-size: 14px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
    letter-spacing: 0.8px;
  }
  @media screen and (max-width: 1200px) {}
</style>
